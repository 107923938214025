import React, { Component } from 'react'

export default class Parallax extends Component {
  render() {
    return (
      <div className='parallax' style={{backgroundImage: `url("${this.props.background}")`}} id={this.props.parallaxID}>
        <div className='caption' id={this.props.textID}>
            <span className='caption_text' style={{fontSize: `${this.props.fontSize}px`}}>{this.props.primary}</span>
        </div>
      </div>
    )
  }
}

import React, { Component } from 'react'
import PropTypes from 'prop-types';

export default class MenuItem extends Component {
  constructor(props){
    super(props);
    this.state = {
      pictureStyle: ""    
    }
    if(this.props.pointerEvent === false && this.props.picture !== ""){
      this.state.pictureStyle = " show";
    }
  }
  render() {
    return (
      <div className='menu_item_wrapper wd-100'>
        <div className='menu_item_container' style={{pointerEvents: `${(this.props.pointerEvent === false || this.props.picture === "") ? "none" : "all"}`}}>
          <div className='menu_item_header'>
            <p className='menu_item'>{this.props.name}</p>
            <p className='menu_item_price'>{this.props.price}</p>
          </div>
          <p className='menu_item_ingredient text-start'>{this.props.ingredient}</p>
        </div>
        <div className={'menu_item_picture_wrapper' + this.state.pictureStyle}>
          <img src={this.props.picture} alt="menu_item_image" className='menu_item_picture' style={{transform: `rotate(${this.props.rotate}deg)`, width: `${this.props.width}%`, height: `${this.props.height}%`}}></img>
        </div>
      </div>
    )
  }
}

MenuItem.propTypes = {
  name: PropTypes.string,
  price: PropTypes.string,
  ingredient: PropTypes.string,
  picture: PropTypes.string,
  pointerEvent: PropTypes.bool
}

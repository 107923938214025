import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import '../pages/styles/navbar.css';
import Logo from '../img/VietnamBistroLogo.png';
import LinkButton from './link_button';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';

export default class NavBar extends Component {
  constructor(props){
    super(props);
    this.state = {
      mini_nav : 
        <div className='navbar_container'> 
          <div className='nav_icon_wrapper'>
            <div className='nav_icon_container'><MenuIcon onClick={(event) => this.menuClick(event)} id='list_menu'></MenuIcon></div>
          </div>
          <div className='logo_wrapper resize'>
            <Link className='img_link' id='logo_link' to='/'><img src={Logo} alt='logo' id='main_logo'></img></Link>
          </div>
        </div>,
      normal_nav: 
        <div className='navbar_container'>
          <div className='logo_wrapper'>
            <Link className='img_link' id='logo_link' to='/'><img src={Logo} alt='logo' id='main_logo'></img></Link>
          </div>
          <div className='nav_wrapper'>
            <LinkButton href='/menu' primary='Menu'></LinkButton>
            <LinkButton href='/about' primary='About'></LinkButton>
            <LinkButton href='/contact-us' primary='Contact Us'></LinkButton>
          </div> 
        </div>,
      nav_dropdown: 
        <div className='nav_dropdown_wrapper' style={{transform: `translateX(-${window.innerWidth}px)`}}>
          <div className='nav_dropdown_container'>
            <div className='nav_dropdown_menu_icon'>
              <div className='nav_menu_icon_wrapper'>
                <div className='nav_icon_container'><MenuOpenIcon onClick={(event) => this.menuClick(event)} id='open_menu'></MenuOpenIcon></div>
              </div>
              <div className='logo_wrapper resize'>
                <Link className='img_link' id='logo_dropdown_link' to='/'><img src={Logo} alt='logo' id='main_logo'></img></Link>
              </div>
            </div>
            <div className='nav_dropdown_menu' style={{minHeight: `${window.innerHeight}px`}}>
              <LinkButton href='/menu' primary='Menu'></LinkButton>
              <LinkButton href='/about' primary='About'></LinkButton>
              <LinkButton href='/contact-us' primary='Contact Us'></LinkButton>
            </div>  
          </div>
        </div>
    }
    this.state.navbar = (window.innerWidth >= 1000) ? this.state.normal_nav : this.state.mini_nav;
    const handleResize = (event) => this.handleResize(event);
    window.addEventListener('resize', handleResize);
  }
  
  render() {
    return (
      <div className='navbar_wrapper'>
        {this.state.navbar}
        {this.state.nav_dropdown}
      </div>
    )
  }

  // Resize the navigation bar
  handleResize(event){
    event.preventDefault();
    const logo_menu = document.getElementsByClassName('navbar_wrapper')[0];
    const width = (window.innerWidth >= 600) ? window.innerWidth : 600;
    const navbar = document.getElementsByClassName('navbar_wrapper')[0];
    if(navbar.offsetWidth >= 1000){
      this.setState({navbar: this.state.normal_nav});
      this.setState({nav_dropdown:
        <div className='nav_dropdown_wrapper' style={{transform: `translateX(-${window.innerWidth}px)`}}>
          <div className='nav_dropdown_container'>
            <div className='nav_dropdown_menu_icon'>
              <div className='nav_menu_icon_wrapper'>
                <div className='nav_icon_container'><MenuOpenIcon onClick={(event) => this.menuClick(event)} id='open_menu'></MenuOpenIcon></div>
              </div>
              <div className='logo_wrapper resize'>
                <Link className='img_link' id='logo_link' to='/'><img src={Logo} alt='logo' id='main_logo'></img></Link>
              </div>
            </div>
            <div className='nav_dropdown_menu' style={{minHeight: `${window.innerHeight - logo_menu.clientHeight}px`}}>
              <LinkButton href='/menu' primary='Menu' linkStyle={{fontSize: `${width * 0.035}px`}}></LinkButton>
              <LinkButton href='/about' primary='About' linkStyle={{fontSize: `${width * 0.035}px`}}></LinkButton>
              <LinkButton href='/contact-us' primary='Contact Us' linkStyle={{fontSize: `${width * 0.035}px`}}></LinkButton>
            </div>  
          </div>
        </div>
      });
      this.setState({mini_nav: 
        <div className='navbar_container'> 
          <div className='nav_icon_wrapper'>
            <div className='nav_icon_container'><MenuIcon onClick={(event) => this.menuClick(event)} id='list_menu'></MenuIcon></div>
          </div>
          <div className='logo_wrapper resize'>
            <Link className='img_link' id='logo_link' to='/'><img src={Logo} alt='logo' id='main_logo'></img></Link>
          </div>
        </div>
      });
    }
    else{
      this.setState({navbar: this.state.mini_nav});
      const dropdown_link = document.getElementsByClassName('button_link');
      Array.prototype.forEach.call(dropdown_link, function(el){
        el.setAttribute('style', `font-size: ${width * 0.035}px`);
      })
    }
  }

  menuClick(event){
    event.preventDefault();
    const logo_menu = document.getElementsByClassName('navbar_wrapper')[0];
    console.log(logo_menu.clientHeight);
    const width = (window.innerWidth >= 600) ? window.innerWidth : 600;
    const menuIcon = document.getElementById('list_menu');
    if(menuIcon === null){
      this.setState({mini_nav: 
        <div className='navbar_container'> 
          <div className='nav_icon_wrapper'>
            <div className='nav_icon_container'><MenuIcon onClick={(event) => this.menuClick(event)} id='list_menu'></MenuIcon></div>
          </div>
          <div className='logo_wrapper resize'>
            <Link className='img_link' id='logo_link' to='/'><img src={Logo} alt='logo' id='main_logo'></img></Link>
          </div>
        </div>
      });
      this.setState({navbar:         
        <div className='navbar_container'> 
          <div className='nav_icon_wrapper'>
            <div className='nav_icon_container'><MenuIcon onClick={(event) => this.menuClick(event)} id='list_menu'></MenuIcon></div>
          </div>
          <div className='logo_wrapper resize'>
            <Link className='img_link' id='logo_link' to='/'><img src={Logo} alt='logo' id='main_logo'></img></Link>
          </div>
        </div>
      });
      this.setState({nav_dropdown: 
        <div className='nav_dropdown_wrapper' style={{transform: `translateX(-${window.innerWidth}px)`}}>
          <div className='nav_dropdown_container'>
            <div className='nav_dropdown_menu_icon'>
              <div className='nav_menu_icon_wrapper'>
                <div className='nav_icon_container'><MenuOpenIcon onClick={(event) => this.menuClick(event)} id='open_menu'></MenuOpenIcon></div>
              </div>
              <div className='logo_wrapper resize'>
                <Link className='img_link' id='logo_dropdown_link' to='/'><img src={Logo} alt='logo' id='main_logo'></img></Link>
              </div>
            </div>
            <div className='nav_dropdown_menu' style={{minHeight: `${window.innerHeight - logo_menu.clientHeight}px`}}>
              <LinkButton href='/menu' primary='Menu' linkStyle={{fontSize: `${width * 0.035}px`}}></LinkButton>
              <LinkButton href='/about' primary='About' linkStyle={{fontSize: `${width * 0.035}px`}}></LinkButton>
              <LinkButton href='/contact-us' primary='Contact Us' linkStyle={{fontSize: `${width * 0.035}px`}}></LinkButton>
            </div>  
          </div>
        </div>
      });
    }
    else{
      this.setState({mini_nav: 
        <div className='navbar_container'> 
          <div className='nav_icon_wrapper'>
            <div className='nav_icon_container'><MenuOpenIcon onClick={(event) => this.menuClick(event)} id='open_menu'></MenuOpenIcon></div>
          </div>
          <div className='logo_wrapper resize'>
            <Link className='img_link' id='logo_link' to='/'><img src={Logo} alt='logo' id='main_logo'></img></Link>
          </div>
        </div>
      })
      this.setState({navbar: 
        <div className='navbar_container'> 
          <div className='nav_icon_wrapper'>
            <div className='nav_icon_container'><MenuOpenIcon onClick={(event) => this.menuClick(event)} id='open_menu'></MenuOpenIcon></div>
          </div>
          <div className='logo_wrapper resize'>
            <Link className='img_link' id='logo_link' to='/'><img src={Logo} alt='logo' id='main_logo'></img></Link>
          </div>
        </div>
      });
      this.setState({nav_dropdown: 
        <div className='nav_dropdown_wrapper active'>
          <div className='nav_dropdown_container'>
            <div className='nav_dropdown_menu_icon'>
              <div className='nav_menu_icon_wrapper'>
                <div className='nav_icon_container'><MenuOpenIcon onClick={(event) => this.menuClick(event)} id='open_menu'></MenuOpenIcon></div>
              </div>
              <div className='logo_wrapper resize'>
                <Link className='img_link' id='logo_dropdown_link' to='/'><img src={Logo} alt='logo' id='main_logo'></img></Link>
              </div>
            </div>
            <div className='nav_dropdown_menu' style={{minHeight: `${window.innerHeight - logo_menu.clientHeight}px`}}>
              <LinkButton href='/menu' primary='Menu' linkStyle={{fontSize: `${width * 0.035}px`}}></LinkButton>
              <LinkButton href='/about' primary='About' linkStyle={{fontSize: `${width * 0.035}px`}}></LinkButton>
              <LinkButton href='/contact-us' primary='Contact Us' linkStyle={{fontSize: `${width * 0.035}px`}}></LinkButton>
            </div>  
          </div>
        </div>
      })
    }
  }
}

import React from 'react';
import ReactDOM from 'react-dom/client';
import './pages/styles/main.css'
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import NotFound from './pages/NotFound';

const root = ReactDOM.createRoot(document.getElementById('main'));
root.render(
  <Router>
    <Routes>
      <Route path='/' exact element={<App></App>}>
        <Route path='/menu' exact element={<App></App>}></Route>
        <Route path='/contact-us' exact element={<App></App>}></Route>
        <Route path='/about-us' exact element={<App></App>}></Route>
      </Route>
      <Route path='/*' exact element={<NotFound></NotFound>}></Route>
    </Routes>
  </Router>
);

export default root;
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

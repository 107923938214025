import React, { Component } from 'react'
import {
  MDBFooter,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon
} from 'mdb-react-ui-kit';
import './styles/footIcon.css';
import Logo from '../img/VietnamBistroLogo.png';

export default class Footer extends Component {
  render() {
    return (
      <MDBFooter bgColor='light' className='text-center text-lg-start'>
      <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
        <div className='me-5 d-none d-lg-block'>
          <span style={{fontSize: "1.5em"}}>Get connected with us on social networks:</span>
        </div>

        <div className='icon_footer_container'>
          <a href='https://www.facebook.com' className='me-4 text-reset'>
            <MDBIcon color='secondary' fab icon='facebook' size='lg'/>
          </a>
          <a href='https://www.instagram.com/' className='me-4 text-reset'>
            <MDBIcon color='secondary' fab icon='instagram'/>
          </a>
          <a href='https://www.twitter.com/' className='me-4 text-reset'>
            <MDBIcon color='secondary' fab icon='twitter'/>
          </a>
        </div>
      </section>

      <section className=''>
        <MDBContainer className='text-center text-md-start mt-5'>
          <MDBRow className='mt-3'>
            <MDBCol xs='3' sm='7' md='5' lg='4' xl='3' className='mx-auto mb-0'>
              <div className='footer_logo_container'>
                <img src={Logo} className='footer_logo' alt='footer_logo'></img>
                <div>
                  <h6 className='text-uppercase fw-bold mb-4'>
                   Vietnam Bistro
                  </h6>
                </div>
              </div>
            </MDBCol>

            <MDBCol md='3' lg='2' xl='2' className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Useful links</h6>
              <p>
                <a href='menu' className='text-reset'>
                  Menu
                </a>
              </p>
              <p>
                <a href='about-us' className='text-reset'>
                  About Us
                </a>
              </p>
            </MDBCol>

            <MDBCol md='4' lg='3' xl='3' className='mx-auto mb-md-0 mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Contact</h6>
              <p>
                <MDBIcon color='secondary' icon='home' className='me-2' />
                788 East Ave, Chico, CA 95926
              </p>
              <p>
                <MDBIcon color='secondary' icon='envelope' className='me-3' />
                info@example.com
              </p>
              <p>
                <MDBIcon color='secondary' icon='phone' className='me-3' />+01 (530) 433-7108
              </p>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <div className='text-center p-4' style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
        © 2021 Copyright:
        <a className='text-reset fw-bold' href='https://mdbootstrap.com/'>
          MDBootstrap.com
        </a>
      </div>
    </MDBFooter>
    )
  }
}

import React, { Component } from 'react'
import About from '../img/About.png'
import Favorite from '../img/Favorite.png'
import Restaurant from '../img/Store.jpg'
import Eggrolls from '../img/Eggrolls.jpg'
import SpringRolls from '../img/SpringRolls.jpeg'
import FavoriteFoodScreen from '../components/favFoodScreen'
import './styles/textImg.css';
import './styles/parallax.css';
import './styles/description.css';
import Parallax from '../components/parallax';
import VietnamCuisineDescription from '../textFile/vietnamCuisineDescription';
import EggrollDescription from '../textFile/eggrollsDescription';
import SpringRollsDescription from '../textFile/springrollsDescription'

export default class Homepage extends Component {
  constructor(props){
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className='content_holder'>
        <FavoriteFoodScreen></FavoriteFoodScreen>
        <div className='text_img placeholderTitle'>
          <img src={About} alt='text'></img>
        </div>
        <Parallax background={Restaurant} primary='Established in 2000' textID='Restaurant_text' parallaxID='Restaurant_parallax' fontSize={window.innerWidth * 0.030}></Parallax>
        <div className='description_section'>
          <h3 className='description_title'>Vietnamese Cuisine</h3>
          <p className='description_para'>
            {VietnamCuisineDescription}
          </p>
        </div>
        <div className='text_img placeholderTitle'>
          <img src={Favorite} alt='text' style={{width: '40%'}}></img>
        </div>
        <Parallax background={Eggrolls} primary='Crispy and full of texture' textID='Eggrolls_text' parallaxID='Eggrolls_parallax' fontSize={window.innerWidth * 0.030}></Parallax>
        <div className='description_section'>
          <h3 className='description_title'>EggRolls</h3>
          <p className='description_para'>
           {EggrollDescription}
          </p>
        </div>
        <Parallax background={SpringRolls} primary='Fresh and delicacy' textID='Springrolls_text' parallaxID='Springrolls_parallax' fontSize={window.innerWidth * 0.030}></Parallax>
        <div className='description_section'>
          <h3 className='description_title'>SpringRolls</h3>
          <p className='description_para'>
            {SpringRollsDescription}
          </p>
        </div>      
      </div>
    )
  }
}

import React, { Component } from 'react'

export default class LinkButton extends Component {
  render() {
    return (
      <div className='button_wrapper' style={this.props.buttonStyle}>
        <div className='button_container'>
            <a className='button_link' href={this.props.href} style={this.props.linkStyle}>
                <div className='link_name'>
                    <span className='link'>{this.props.primary}</span>
                </div>
            </a>
        </div>
      </div>
    )
  }
}

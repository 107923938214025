import {Routes, Route} from 'react-router-dom';
import NavBar from "./components/navbar";
import Footer from './pages/footer';
import Homepage from "./pages/homepage";
import MenuPage from './pages/menu';
import NotFound from './pages/NotFound';

function App() {
  return (
    <div className="main_screen">
      <NavBar></NavBar>
      <Routes>
        <Route path='/' exact element={<Homepage></Homepage>}></Route>
        <Route path='/menu' exact element={<MenuPage></MenuPage>}></Route>
        <Route path='/*' exact element={<NotFound></NotFound>}></Route>
      </Routes>
      <Footer></Footer>
    </div>
  );
}

export default App;

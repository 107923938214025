import React, { Component } from 'react'
import './styles/menu.css';
import MenuNormal from '../components/menuNormal';
import MenuPhone from '../components/menuPhone';

export default class MenuPage extends Component {
  constructor(props){
    super(props);
    this.state= {
      menu: (window.innerWidth >= 1000) ? <MenuNormal></MenuNormal> : <MenuPhone></MenuPhone> 
    }
    const handleResize = (e) => this.handleResize(e);
    window.addEventListener('resize', handleResize);
  }
  render() {
    return (
      <div className='menu_container'>
        <div className='menu_title_container'>
          <h1 className='menu_title'>Menu</h1>
        </div>
        <section className='menu_content_wrapper'>
          {this.state.menu}
        </section>
      </div>
    )
  }

  handleResize(event){
    event.preventDefault();
    const menu_item_picture = document.getElementsByClassName('menu_item_picture_wrapper');
    const menu_item_container = document.getElementsByClassName("menu_item_container");
    if(window.innerWidth < 1000){
      let item_number = 0;
      this.setState({menu: <MenuPhone></MenuPhone>});
      Array.prototype.forEach.call(menu_item_picture, function(e){
       if(!e.classList.contains("show")){
          if(e.childNodes[0].getAttribute("src") !== "") e.classList.add("show");
          menu_item_container[item_number].setAttribute("style", "pointer-events: none");
       }
       item_number++;
      });
    }
    else{
      let item_number = 0;
      this.setState({menu: <MenuNormal></MenuNormal>});
      Array.prototype.forEach.call(menu_item_picture, function(e){
        if(e.classList.contains("show")){
          e.classList.remove("show");
          if(e.childNodes[0].getAttribute("src") !== "") 
            menu_item_container[item_number].setAttribute("style", "pointer-events: all");
        }
        item_number++;
      })
    }

  }
}

import React, { Component } from 'react'
// import PhoPicture from '../img/Beef Balls Pho.jpg';
import ShakenBeef from '../img/shaken beef.jpg';
import EggrollsPicture from '../img/Eggrolls.jpg';
import SpringRolls from '../img/SpringRolls.jpeg';
import '../pages/styles/fav_screen.css';

class ChainImage{
    constructor(img){
        this.next = {};
        this.img = img;
    }
    nextImg(){return this.next}
    setNextImg(img){this.next = new ChainImage(img);}
}

var chainImg = new ChainImage(ShakenBeef);
chainImg.setNextImg(EggrollsPicture);
chainImg.nextImg().setNextImg(SpringRolls);
chainImg.nextImg().nextImg().next = chainImg;

export default class FavoriteFoodScreen extends Component {
    constructor(props){
        super(props);
        this.state = {
            current_img: chainImg.img,
            next_function: () => this.nextImg(),
            transition_duration: {},
            temp: 0
        }

        setInterval(this.state.next_function, 4000);
    }
    render() {
        return (
            <div className='fav_screen_wrapper'>
                <div className='first_half'></div>
                <div className='fav_screen_container'>
                    <img className='screen_placeholder' src={this.state.current_img} alt='fav_food' id='origin_img'></img>
                    <img className='next_screen_placeholder' src={chainImg.next.img} alt='fav_food' id='next_img'></img>
                </div>
                <div className='second_half'></div>
            </div>
        )
    }
    nextImg(){        
        const base = document.getElementsByClassName('screen_placeholder next')[0];
        if(base !== undefined && base !== null){
            chainImg = chainImg.next;
        }
        this.setState({current_img: chainImg.img});
    }

    componentDidUpdate(){
        const base = document.getElementsByClassName('screen_placeholder next')[0];
        const next = document.getElementsByClassName('next_screen_placeholder next')[0];
        if(base !== undefined && base !== null){
            base.setAttribute('style', `transform: translateX(0px)`);
            next.setAttribute('style', `transform: translateX(0px)`);
        }
        else{
            const origin = document.getElementsByClassName('screen_placeholder')[0];
            const next_origin = document.getElementsByClassName('next_screen_placeholder')[0];
            if(origin !== undefined && origin !== null) {
                origin.setAttribute('style', `transform: translateX(-${origin.clientWidth}px); transition-duration: 1.5s`);
                next_origin.setAttribute('style', `transform: translateX(-${origin.clientWidth * 1.0001}px)`);
            }
        }
        setTimeout(document.getElementsByClassName('screen_placeholder')[0].classList.toggle('next'), 1000);
        setTimeout(document.getElementsByClassName('next_screen_placeholder')[0].classList.toggle('next'), 1000);
    }
}

